@tailwind base;
@tailwind components;
@tailwind utilities;



@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin-button {
  animation: spin 5s linear infinite; /* Adjust duration as needed */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


/* General Image Styles */
img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

/* Hexagon Gallery Grid */
.hexagon-gallery {
  margin: auto;
  margin-top: 50px;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 200px;
  grid-gap: 14px;
  padding-bottom: 50px;
}

/* Hexagon Shape */
.hex {
  position: relative;
  width: 240px;
  height: 265px;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Black overlay with 60% opacity */
.hex::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

/* Image inside hexagon */
.hex img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

/* Text inside hexagon */
.hex p {
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  z-index: 2;
}

/* Specific Hexagon Positioning */
.hex-1 { grid-row-start: 1; grid-column: 4 / span 2; }
.hex-2 { grid-row-start: 2; grid-column: 1 / span 2; }
.hex-3 { grid-row-start: 2; grid-column: 3 / span 2; }
.hex-4 { grid-row-start: 2; grid-column: 5 / span 2; }
.hex-5 { grid-row-start: 2; grid-column: 7 / span 2; }
.hex-6 { grid-row-start: 3; grid-column: 2 / span 2; }
.hex-7 { grid-row-start: 3; grid-column: 4 / span 2; }
.hex-8 { grid-row-start: 1; grid-column: 6 / span 2; }

/* Media query for smaller screens */
@media (max-width: 768px) {
  .hexagon-gallery {
    grid-template-columns: repeat(2, 1fr); /* Reduce columns to 2 */
    grid-auto-rows: 70px; /* Adjust row height */
    grid-gap: 5px; /* Adjust grid gap */
  }

  .hex {
    width: 80px; /* Smaller width */
    height: 90px; /* Smaller height */
  }

  .hex p {
    font-size: 0.8rem; /* Smaller text */
  }
}


.rgb-button {
  background: linear-gradient(45deg, #3b82f6, #0d9488, #0000ff);
  background-size: 600% 600%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


.custom-width-7col {
  max-width: calc(100% / 12 * 10); /* 7 columns out of 12 */
}

section {
  @apply mb-5;
}

.active-link {
  @apply text-theme-purple;
}

.header {
  @apply flex flex-col py-4 mb-4 justify-between;
}

.hero {
  @apply container px-6 flex flex-col mx-auto ;
}

.header {
  position: sticky;
  top: 0;
  z-index:50; /* Ensure this is higher than the hero section */
}

.hero {
  position: relative; /* Or ensure z-index is lower if you set it */
}

.portofolio-card:hover .img-hover {
  @apply opacity-25;
}

@media (min-width: 1024px) {
  .header {
    @apply container w-full flex-row py-8 px-2 mt-2 mb-4 mx-auto items-center content-center;
  }

  .hero {
    @apply container flex-row mx-auto mt-8;
  }
}


/* Custom styles for Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(160, 157, 157, 0.311);
  /* Semi-transparent background */
  border-radius: 50%;
  font-size: 30px;

  /* Circular shape */
  width: 33px;
  /* Size of the buttons */
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  /* Color of the arrow icon */
}

/* Custom styles for the arrow icons */
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 30px;
  /* Size of the arrow */
  color: #000;
  /* Color of the arrow */
  content: '';
  /* Override default content */
  border: solid #000;
  /* Arrow color */
  border-width: 0 3px 3px 0;
  /* Arrow thickness */
  display: inline-block;
  padding: 5px;
}

/* Rotate arrows to point in the correct direction */
.swiper-button-next::after {
  transform: rotate(-45deg);
  /* Right arrow */
}

.swiper-button-prev::after {
  transform: rotate(135deg);
  /* Left arrow */
}

/* Hover effect for buttons */
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* Darker background on hover */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: bold !important;
}



/* 
product icon animation */
.MyGradient {
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,       /* Start with transparency */
    rgba(0, 0, 0, 1) 100px,      /* Solid mask from 5px */
    rgba(0, 0, 0, 1) calc(100% - 100px), /* Solid mask till 100% - 5px */
    rgba(0, 0, 0, 0) 100%      /* End with transparency */
  );
}

